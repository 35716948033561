import { bemJoin } from 'bem-join';
import React from 'react';
import { IconContext } from 'react-icons';

import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import MainNavigation from 'molecules/MainNavigation';

import './Layout.scss';

let CookieConsent = () => null;
if (typeof document !== 'undefined') {
  // eslint-disable-next-line global-require
  CookieConsent = require('molecules/CookieConsent').default;
}

export const b = bemJoin('layout');

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => (
  <IconContext.Provider value={{ size: '24', className: 'icon' }}>
    <div className={b()}>
      <Header>
        <MainNavigation />
      </Header>
      {children}
      <Footer />
      <CookieConsent />
    </div>
  </IconContext.Provider>
);

export default Layout;
