import { bemJoin } from 'bem-join';
import React from 'react';
import { Link } from 'gatsby';

import Logo from '@eventstrive/components/Logo';
import Content from '@eventstrive/components/Content';

import './Header.scss';

const b = bemJoin('header');

interface HeaderProps {
  children?: React.ReactNode;
}

const Header = ({
  children,
}: HeaderProps) => (
  <div className={b()}>
    <Content noPadding>
      <div className={b('content')}>
        <Link to="/">
          <Logo />
        </Link>
        {children}
      </div>
    </Content>
  </div>
);

export default Header;
