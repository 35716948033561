
import { bemJoin } from 'bem-join';
import React from 'react';
import slug from 'slug';

import Headline from '@eventstrive/components/Headline';
import Image from '@eventstrive/components/Image';
import Content from '@eventstrive/components/Content';
import Button from 'atoms/Button';

import liveStreamImage from 'images/features/livestream.png';
import voiceImage from 'images/features/voice.png';
import recordingsImage from 'images/features/recordings.png';
import pollsQuestionsImage from 'images/features/polls-questions.png';

import './Features.scss';

interface Feature {
  headline: string;
  text: string;
  image: string;
  alt: string;
  topic?: string;
  slug?: string;
  link?: string;
}

export let features: Feature[] = [
  {
    topic: 'Live Streaming',
    headline: 'Live events made easy',
    text: `
      Being part of an event should be simple and straightforward, even if you are not on site.
      We give you a complete event experience in a single platform wherever you are. 
    `,
    image: liveStreamImage,
    alt: 'An exemplary image of a presenter speaking on a stage at a conference',
  },
  {
    topic: 'Networking',
    headline: 'Meet the right people',
    text: `
      With intelligent matchmaking we hook you up with the contacts that suit your needs and interests.
      For a flawless communication among each other we provide voice channels and a feature rich text chat.
    `,
    image: voiceImage,
    alt: 'A UI snippet showing the design for the interaction area where the voice channels are currently selected',
  },
  {
    topic: 'Recordings',
    headline: 'Amazing events on demand',
    text: `
    Every event will be recorded so you can be part of it whenever you like.
    The communication and networking area gives you the unique chance to have a lively exchange even after the actual event took place.
    `,
    image: recordingsImage,
    alt: 'Four exemplary images of conferences',
  },
  {
    topic: 'Polls and Questions',
    headline: 'Let us hear your voice',
    text: `
      During the live sessions you are able to ask questions which are answered by the speaker and other experts.
      To provide even more interaction the speaker is able to send polls to all participants, on site and off site.
    `,
    image: pollsQuestionsImage,
    alt: 'Two overlapping UI snippets showing the design for questions and polls',
  },
];

features = features.map((feature): Feature => ({
  ...feature,
  slug: slug(feature.topic, { lower: true }),
}));

const b = bemJoin('features');

const Features = () => (
  <>
    <div id="features" />
    <Content>
      <div className={b()}>
        {features.map(({
          headline,
          text,
          image,
          link,
          topic,
          alt,
          slug,
        }) => (
          <section key={headline} id={slug}>
            <div className={b('text')}>
              {topic && <div className={b('topic')}><span>{topic}</span></div>}
              <Headline size={2} text={headline} wrap />
              <p>{text}</p>
              {link && <Button to={link} text="More information" color="tertiary" />}
            </div>
            <div className={b('image-wrapper')}>
              <Image src={image} alt={alt} />
            </div>
          </section>
        ))}
      </div>
    </Content>
  </>
);

export default Features;
