import { bemJoin } from 'bem-join';
import React from 'react';

import './Headline.scss';

const b = bemJoin('headline');

interface HeadlineProps {
  size: 1 | 2 | 3 | 4 | 5 | 6;
  text: string;
  color?: 'white' | 'grey';
  className?: string;
  isCentered?: boolean;
  wrap?: boolean;
}

const Headline = ({
  size,
  text,
  color,
  className = '',
  isCentered = false,
  wrap = false,
}: HeadlineProps) => {
  const H = (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLHeadingElement>,
      HTMLHeadingElement
    >,
  ): JSX.Element => React.createElement(`h${size}`, props);

  return (
    <H
      className={`${b({
        white: color === 'white',
        grey: color === 'grey',
        centered: isCentered,
        ellipsis: !wrap,
      })} ${className}`}
    >
      <span>{ text }</span>
    </H>
  );
};

export default Headline;
